import React from "react"
import logo from "../images/item_m6_logo.png"
import SearchIcon from "../images/SearchIcon.svg"
import styled from "styled-components"
import { Link } from "gatsby"

const StyledWrapper = styled.div`
  .logo {
    object-fit: contain;
  }

  .dropdown {
    position: relative;
    display: inline-block;
    cursor: pointer;
    svg {
      margin-left: 10px;
    }
    a {
      display: flex;
    }
  }
  
  .dropdown:hover {
    svg {
      transform: rotateX(180deg);
      transition: 0.5s;
    }
  }

  .dropdown:hover h6 {
    color: grey;
  }
  

  .dropdown-content {
    visibility: hidden;
    opacity: 0;
    position: absolute;
    color: #111;
    cursor: pointer;
    background-color: white;
    min-width: 250px;
    z-index: 1;
    padding: 20px;
    left: -80px;
    top: 20px;
    transition: visibility 300ms, opacity 300ms;
  }

  .dropdown-content p:hover {
    color: grey;
  }

  .dropdown:hover .dropdown-content {
    visibility: visible;
    opacity: 1;
  }

  li {
    display: flex;
    align-items: flex-end;
    text-decoration: none;
  }

  a:hover {
    text-decoration: none;
    color: #111;
  }

  .row {
    flex-wrap: nowrap;
    height: 100%;
  }

  nav h6 {
    margin-bottom: 0;
  }

  .navi {
    padding-left: 5rem;
    padding-right: 10rem;
  }
`

const navbarMenu = () => {
  return (
    <StyledWrapper className="d-none d-lg-block">
      <div className="container-fluid py-md-3">
        <div className="row">
          <div className="col-1 ms-5">
            <Link to='https://itemm6usa.com/'>
            <img
              className="img-fluid mt-md-n2 mt-sm-none"
              src={logo}
              alt="Item M6 Logo"
            />
            </Link>
          </div>
          <div className="col-md-10">
            <div className="row d-flex">
              <nav className="col-8 mr-auto d-flex justify-content-around navi">
                <li>
                  <Link to="https://itemm6usa.com/collections/bodywear">
                    <h6>BODYWEAR</h6>
                  </Link>
                </li>
                <li>
                <Link to="https://itemm6usa.com/collections/legwear">
                  <h6>LEGWEAR</h6>
                </Link>
                </li>
                <li>
                <div className="dropdown">
                  <Link to="https://itemm6usa.com/pages/compression-benefits">
                    <h6 className="d-inline-flex">
                      ABOUT ITEM M6{" "}
                    </h6>{" "}
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-chevron-down"
                        viewBox="0 0 16 16"
                    >
                      <path
                          fillRule="evenodd"
                          d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
                      />
                    </svg>
                  </Link>
                  <div className="dropdown-content text-uppercase">
                    <Link to="https://itemm6usa.com/pages/compression-benefits"><p>why we're different</p></Link>
                    <Link to="https://itemm6usa.com/pages/in-the-press"><p>press</p></Link>
                    <Link to="https://itemm6usa.com/pages/faq"><p>faq</p></Link>
                    <Link to="https://itemm6usa.com/blogs/item-m6/"><p>blog</p></Link>
                  </div>
                </div>
                </li>
                <li>
                  <Link to="https://itemm6usa.com/pages/returns-reorders"><h6>RETURNS</h6></Link>
                </li>
                <li>
                  <Link to="https://itemm6usa.com/pages/contact"><h6>CONTACT US</h6></Link>
                </li>
              </nav>
            </div>
          </div>
          <div className="col-1">
            <a href="https://itemm6usa.com/search" className="d-block pr-3">
              <img src={SearchIcon} alt="" className="img-fluid ml-auto" />
            </a>
          </div>
        </div>
      </div>
    </StyledWrapper>
  )
}

export default navbarMenu


/**
 * <StyledWrapper className="d-none d-lg-block">
      <div className="container-fluid py-md-3">
        <div className="row">
          <div className="col-1 ms-5">
            <Link to='https://itemm6usa.com/'>
            <img
              className="img-fluid mt-md-n2 mt-sm-none"
              src={logo}
              alt="Item M6 Logo"
            />
            </Link>
          </div>
          <div className="col-md-10">
            <div className="row d-flex">
              <nav className="col-8 mx-auto d-flex justify-content-around mt-md-1 px-md-2">
                <Link to="https://itemm6usa.com/collections/bodywear">
                  <h6>BODYWEAR</h6>
                </Link>
                <Link to="https://itemm6usa.com/collections/legwear">
                  <h6>LEGWEAR</h6>
                </Link>
                <div className="dropdown">
                  <Link to="https://itemm6usa.com/pages/compression-benefits">
                    <h6 className="d-inline-flex">
                      ABOUT ITEM M6{" "}
                    </h6>{" "}
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-chevron-down"
                        viewBox="0 0 16 16"
                    >
                      <path
                          fillRule="evenodd"
                          d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
                      />
                    </svg>
                  </Link>
                  <div className="dropdown-content text-uppercase">
                    <Link to="https://itemm6usa.com/pages/compression-benefits"><p>why we're different</p></Link>
                    <Link to="https://itemm6usa.com/pages/in-the-press"><p>press</p></Link>
                    <Link to="https://itemm6usa.com/pages/faq"><p>faq</p></Link>
                    <Link to="https://itemm6usa.com/blogs/item-m6/"><p>blog</p></Link>
                  </div>
                </div>
                <Link to="https://itemm6usa.com/pages/returns-reorders"><h6>RETURNS</h6></Link>
                <Link to="https://itemm6usa.com/pages/contact"><h6>CONTACT US</h6></Link>
              </nav>
            </div>
          </div>
          <div className="col-1">
            <a href="https://itemm6usa.com/search" className="d-block pr-3">
              <img src={SearchIcon} alt="" className="img-fluid ml-auto" />
            </a>
          </div>
        </div>
      </div>
    </StyledWrapper>
 */