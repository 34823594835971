import React, { useState } from "react"
import styled from "styled-components"
import facebook from "../images/Social Media/Facebook.svg"
import instagram from "../images/Social Media/Instagram.svg"
import twitter from "../images/Social Media/Twitter.svg"
import email from "../images/Social Media/email.svg"
import userAvatar from "../images/UserAvatar.svg"
import cart from "../images/Cart.svg"

const StyledWrapper = styled.div`
  .headerMenuContainer {
    background: #f0f0f0;
  }

  .headerMenu-socialIcons img {
    margin: 10px;
    height: 20px;
  }
  .headerMenu-loginIcons img {
    margin: 10px;
    height: 20px;
  }
`

const HeaderMenu = () => {
  const [cartTotal, setCartTotal] = useState(0)
  return (
    <StyledWrapper>
      <div className="container-fluid headerMenuContainer d-lg-block d-none">
        <div className="row justify-content-between align-items-center">
          <div className="col-4 d-flex justify-content-between align-items-center">
            <div className="headerMenu-socialIcons">
              <img src={facebook} alt="" />
              <img src={instagram} alt="" />
              <img src={twitter} alt="" />
              <img src={email} alt="" />
            </div>
            <div className="text-white">1-866-655-6605</div>
          </div>
          <div className="col-6 d-flex justify-content-end align-items-center headerMenu-loginIcons">
            <img src={userAvatar} alt="" />
            Login
            <img src={cart} alt="" />
            <span className="text-white">{cartTotal}</span>
          </div>
        </div>
      </div>
    </StyledWrapper>
  )
}

export default HeaderMenu
