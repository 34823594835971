import React from "react"
import styled from "styled-components"

const StyledWrapper = styled.div`
  .mobileScience1 {
    min-width: 150px;
    max-width: 150px;
    min-height: 190px;
    max-height: 190px;
    object-fit: cover;
  }

  .mobileScience3 {
    min-width: 53vw;
    max-width: 50vw;
    max-height: 300px;
    min-height: 300px;
    object-fit: cover;
  }

  .mobileScience2 {
    min-width: 160px;
    max-width: 160px;
    min-height: 150px;
    max-height: 150px;
    object-fit: cover;
    margin-right: 5px;
  }

  .description-img {
    object-fit: cover;
    height: 200px;
    width: 200px;
  }

  .science-container {
    background: #f1e9de;
  }

  a {
    text-decoration: underline;
  }
`

const poweredByScience = props => {
  return (
    <StyledWrapper>
      <div className="container-fluid d-sm-block d-md-none science-container pb-5">
        <div className="row align-items-center">
          <div className="col-12 d-flex justify-content-center">
            <img
              src={props?.collectionImages[0]}
              alt=""
              className="img-fluid mobileScience1 ml-n2"
            />
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-12 d-flex align-items-start justify-content-center">
            <img
              src={props?.collectionImages[1]}
              alt=""
              className="img-fluid mobileScience2"
            />
            <img
              src={props?.collectionImages[2]}
              alt=""
              className="img-fluid mobileScience3"
            />
          </div>
        </div>
        <div className="row mt-4 align-baseline">
          <div className="col-12 text-uppercase text-center font-weight-bolder">
            <h2 className="mb-3">{props?.title}</h2>
          </div>
        </div>
        <div className="row mt-2 align-content-start">
          <div className="col-11 mx-auto d-flex justify-content-between align-items-start flex-wrap">
            <img
              src={props?.collectionImages[3]}
              alt=""
              className="img-fluid description-img d-block mx-auto mb-2"
            />
            <div className="ms-4 text-center">
              <p>{props.description}</p>
              <p className="mt-4 text-capitalize">
                <a href={props?.cta.handle}>{props?.cta.title}</a>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="12"
                  fill="currentColor"
                  className="bi bi-chevron-right fw-bolder"
                  viewBox="0 0 16 16"
                >
                  <path
                    fillRule="evenodd"
                    d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
                  />
                </svg>
              </p>
            </div>
          </div>
        </div>
      </div>
    </StyledWrapper>
  )
}

export default poweredByScience
