import { ItemM6HomePageData } from "./landingPageTypes"

export const ItemM6HomeData: ItemM6HomePageData = {
  heroCarousel: {
    powerpants: [
      {
        heading: "Patented Shape Technology",
        description: `Our new power pants are luxurious every day essentials. Contouring compression technology firms, strengthens and creates a beautiful figure while providing a feel better effect.`,
        image:
          "https://firebasestorage.googleapis.com/v0/b/item-m6.appspot.com/o/TopCarousel%2FPowerPants%2Fitemm6_hero_1.jpg?alt=media&token=796ed3bf-72e6-411b-aa5c-685a4d9286d6",
      },
      {
        heading: "Perfect Fit and Ultimate Comfort",
        description: `The innovative shape effect of our form fit threads support your every move while giving your booty a lift and your body a power boost!`,
        image:
          "https://firebasestorage.googleapis.com/v0/b/item-m6.appspot.com/o/TopCarousel%2FPowerPants%2Fitemm6_hero_2.jpg?alt=media&token=560a8044-8f7e-40da-ad52-de0bf8b37373",
      },
      {
        heading: "Classic in Design",
        description: `The Power Pants are versatile like no other - from casual to chic, we have options for all of your life moments with elevated pieces that are not only functional but fashionable.`,
        image:
          "https://firebasestorage.googleapis.com/v0/b/item-m6.appspot.com/o/TopCarousel%2FPowerPants%2Fitemm6_hero_3.jpg?alt=media&token=059c1e72-0cc2-46f1-84ef-05ebb2eba4ee",
      },
    ],
    mesh: [
      {
        heading: `ULTRA DELICATE FEEL + POWERFUL MESH`,
        description: `The first all mesh line with a feel good effect is here. The super-light, silky fine mesh material with 360 degree elasticity forms gently to the body and the breathable high-tech net structure provides optimal support for your wearing comfort. These luxurious layers are an everyday elevated essential with multiple style options to choose from.`,
        image:
          "https://firebasestorage.googleapis.com/v0/b/item-m6.appspot.com/o/TopCarousel%2FMesh%2FheroCarouselImg1.jpg?alt=media&token=4ad232a5-f0e3-4eec-bed6-695edd47fd36",
      },
      {
        heading: "Perfect Fit and Ultimate Comfort",
        description: `The innovative shape effect of our form fit threads support your every move while giving your booty a lift and your body a power boost!`,
        image:
          "https://firebasestorage.googleapis.com/v0/b/item-m6.appspot.com/o/TopCarousel%2FMesh%2FheroCarouselImg2.jpg?alt=media&token=d474ffa3-a5d2-4d3d-97a3-e1277bc2f03a",
      },
      {
        heading: "STYLISH VERSATILITY",
        description: `From thongs to briefs, low back to v neck, and adjustable straps, we have your styling needs covered.`,
        image:
          "https://firebasestorage.googleapis.com/v0/b/item-m6.appspot.com/o/TopCarousel%2FMesh%2FheroCarouselImg3.jpg?alt=media&token=5c33b9d1-7fd2-4b09-b66e-bf182bf2ac4c",
      },
    ],
  },
  allowToSurprise: {
    title: "allow us to surprise you",
    backgroundBanner:
      "https://firebasestorage.googleapis.com/v0/b/item-m6.appspot.com/o/AllowSurprise%2Fother-options-background.jpg?alt=media&token=ab5636f0-24c0-470b-9ec8-0c891ff013b2",
    description:
      "Find out what happens when compression technology developed for the medical industry finds it’s way to the world of fashion.",
    boxData: [
      {
        title: "FEEL GOOD FACTOR + BODY BENEFITS",
        image:
          "https://firebasestorage.googleapis.com/v0/b/item-m6.appspot.com/o/AllowSurprise%2Fbox_1.jpg?alt=media&token=89ef4556-0d71-47cf-a966-8df8ac26656b",
        description:
          "Decades of experience in compression and innovation make it possible for us to reshape the status quo of shape garments. Our compression technology promotes circulation, supports the silhouette and allows it to move without constriction for a boost of energy.",
      },
      {
        title: "EUROPEAN CRAFTMANSHIP + SUSTAINABLE DESIGN",
        image:
          "https://firebasestorage.googleapis.com/v0/b/item-m6.appspot.com/o/AllowSurprise%2Fbox_2.jpg?alt=media&token=468e296d-d253-4d8b-a80d-0d0f7764c264",
        description:
          "As a family owned heritage brand, we knit over 80 years of medical knowledge and expertise into each garment to support and inspire women to move with confidence. We are uncompromising in both quality and design, utilizing the finest materials that are sustainably sourced and manufactured in Europe",
      },
      {
        title: "UNPARALLELED ALL DAY COMFORT",
        image:
          "https://firebasestorage.googleapis.com/v0/b/item-m6.appspot.com/o/AllowSurprise%2Fbox_3.jpg?alt=media&token=e999da24-11e8-4d04-91ba-15c98f6e401e",
        description:
          "Premium, quality material mixes that create a unique feel-better effect with a wow factor. Contouring form fit threads feel like a luxurious second skin and provide all day wearing comfort and style.",
      },
      {
        title: "BY WOMEN FOR WOMEN",
        image:
          "https://firebasestorage.googleapis.com/v0/b/item-m6.appspot.com/o/AllowSurprise%2Fbox_4.jpg?alt=media&token=14d78b58-c3de-416a-b029-a02c764b9397",
        description:
          "Our Next - Level Bodywear goes beyond shapewear that is solely meant for special occasions. We focus on what women need in their daily life in order to give them a good feeling for all of life‘s moments. Our bodywear is designed by women and with women in mind, to be supportive, wellness-focused, and beautiful, just like you.",
      },
    ],
  },
  midCarousel: {
    powerpantsData: {
      title: "INTRODUCING POWER PANTS",
      primaryDescription:
        "Fashionable and comfortable outerwear that helps you look great and feel great.",
      carouselData: [
        {
          heading: "DENIM HIGH RISE PANTS",
          description: `The Denim High Rise Pants are the luxury version of the classic 5-pocket jeans. Thanks to the patented shape technology, it contours the legs and lifts the bottom in a completely new way - it is extremely comfortable and at the same time provides support - 24/7.`,
          price: "$178",
          image:
            "https://firebasestorage.googleapis.com/v0/b/item-m6.appspot.com/o/MidCarousel%2FPowerPants%2Fmid_car_1.jpg?alt=media&token=51f59fa3-e96b-4186-a345-72a7cf3f136a",
          shopLink:
            "https://itemm6usa.com/collections/denim-pants/products/denim-high-rise-pants",
        },
        {
          heading: "CLEAN HIGH RISE PANTS",
          description: `With classic details, these are next level power pants. Matte black with a front seam, it offers a classy look, and the patented shape effect models the leg and lifts the bottom.`,
          price: "$168",
          image:
            "https://firebasestorage.googleapis.com/v0/b/item-m6.appspot.com/o/MidCarousel%2FPowerPants%2Fmid_car_2.jpg?alt=media&token=9f06ad53-722f-4c63-b288-7273c4f5ea0c",
          shopLink:
            "https://itemm6usa.com/collections/denim-pants/products/clean-high-rise-pants",
        },
        {
          heading: "PLEATED RIDER LEGGINGS",
          description: `Revolutionary, woven high-tech compression threads precisely shape and support a flawless silhouette. The mesh panels at the waistband and sides give these shaping leggings their exquisite look, while the wide top band ensures comfortable all day wear.`,
          price: "$168",
          image:
            "https://firebasestorage.googleapis.com/v0/b/item-m6.appspot.com/o/MidCarousel%2FPowerPants%2Fmid_car_3.jpg?alt=media&token=ce1282ef-c88b-46f2-9a88-25d7f5560efb",
          shopLink:
            "https://itemm6usa.com/collections/denim-pants/products/pleated-rider-leggings",
        },
        {
          heading: "POWER MESH LEGGINGS",
          description: `These leggings are a figure miracle with mesh power and comfort that you'll love at first wear. The leggings shape you into your best form using the ultimate in compression technology while the feminine design gives you a breathtakingly beautiful silhouette.`,
          price: "$144",
          image:
            "https://firebasestorage.googleapis.com/v0/b/item-m6.appspot.com/o/MidCarousel%2FPowerPants%2Fmid_car_4.jpg?alt=media&token=cbfac97e-9da5-4a06-b5df-e9dcba57efa2",
          shopLink:
            "https://itemm6usa.com/collections/denim-pants/products/power-mesh-leggings",
        },
      ],
    },
    meshData: {
      title: "INTRODUCING THE ALL MESH POWER COLLECTION:",
      primaryDescription: "Silky support for your versatile needs",
      carouselData: [
        {
          heading: "ALL MESH BODYSUIT",
          description: `Delicate yet powerful mesh that feels like a hug while gently supporting your every curve.`,
          price: "$128",
          image:
            "https://firebasestorage.googleapis.com/v0/b/item-m6.appspot.com/o/MidCarousel%2FMesh%2FmeshMiddleCarouselImg1.jpeg?alt=media&token=096fc86d-c3cc-4d76-9586-1f7403ea353d",
          shopLink:
            "https://itemm6usa.com/collections/shapewear-bodysuits/products/all-mesh-bodysuit",
        },
        {
          heading: "ALL MESH V-NECK THONG BODYSUIT",
          description: `This form supporting bodysuit with a gentle shaping effect combines fashion, fit, and function.`,
          price: "$128.00",
          image:
            "https://firebasestorage.googleapis.com/v0/b/item-m6.appspot.com/o/MidCarousel%2FMesh%2FmeshMiddleCarouselImg2.jpeg?alt=media&token=b8d553a9-7963-467c-a4ef-a00993a59478",
          shopLink:
            "https://itemm6usa.com/collections/shapewear-bodysuits/products/power-mesh-vneck-shape-thong-bodysuit",
        },
        {
          heading: "ALL MESH LOW BACK BODYSUIT",
          description: `Low back design with adjustable straps offer versatility for everyday wear.`,
          price: "$128",
          image:
            "https://firebasestorage.googleapis.com/v0/b/item-m6.appspot.com/o/MidCarousel%2FMesh%2FmeshMiddleCarouselImg3.jpeg?alt=media&token=0e1451ff-12d3-4fff-a4e7-ae003493ab8d",
          shopLink:
            "https://itemm6usa.com/collections/shapewear-bodysuits/products/all-mesh-low-back-bodysuit",
        },
        {
          heading: "ALL MESH BRALETTE",
          description: `The unique power mesh provides support, and remains elastic without constriction.`,
          price: "$68",
          image:
            "https://firebasestorage.googleapis.com/v0/b/item-m6.appspot.com/o/MidCarousel%2FMesh%2FmidCarouselImg4.jpeg?alt=media&token=c2345465-5969-469f-bab7-d46846a2402a",
          shopLink:
            "https://itemm6usa.com/collections/shapewear-tops/products/power-mesh-bralette",
        },
        {
          heading: "ALL MESH BRAZILIAN BRIEF",
          description: `Invisible under clothing and luxuriously comfortable for all day wear.`,
          price: "$58",
          image:
            "https://firebasestorage.googleapis.com/v0/b/item-m6.appspot.com/o/MidCarousel%2FMesh%2FmidCarouselImg5.jpeg?alt=media&token=f45b0b72-ac60-4b18-8ff9-a05f63a60a3e",
          shopLink:
            "https://itemm6usa.com/collections/compression-briefs/products/power-mesh-brazilian-brief",
        },
      ],
    },
  },
  poweredByScience: {
    powerpants: {
      title: "POWERED BY SCIENCE",
      description: `Behind ITEM m6's smart innovation is our parent company medi, a leader in the world of medical compression technology. As a family-owned brand, we take pride in our 80 years of medical compression knitting expertise and know how. We combine heritage, cutting-edge technology and design in each stitch to offer feel better, sustainable products with a wow effect.`,
      cta: {
        title: "learn more",
        handle: "https://itemm6usa.com/pages/compression-benefits",
      },
      collectionImages: [
        "https://firebasestorage.googleapis.com/v0/b/item-m6.appspot.com/o/PoweredByScience%2FPowerPants%2FpoweredByScience1.jpg?alt=media&token=8f7a700b-268c-4a6b-a02a-11468fd8b372",
        "https://firebasestorage.googleapis.com/v0/b/item-m6.appspot.com/o/PoweredByScience%2FPowerPants%2FpoweredByScience2.jpg?alt=media&token=8237717c-1c82-45f2-a5e8-de4ca83b4db8",
        "https://firebasestorage.googleapis.com/v0/b/item-m6.appspot.com/o/PoweredByScience%2FPowerPants%2FpoweredByScience3.jpg?alt=media&token=9373ba3a-32ad-4d59-b82c-c622a96c719f",
        "https://firebasestorage.googleapis.com/v0/b/item-m6.appspot.com/o/PoweredByScience%2FMesh%2FpoweredByScience.png?alt=media&token=c1294372-9ac7-4242-ac54-1bc167287847",
      ],
    },
    mesh: {
      title: "POWERED BY SCIENCE",
      description: `Behind ITEM m6's smart innovation is our parent company medi, a leader in the world of medical compression technology. As a family-owned brand, we take pride in our 80 years of medical compression knitting expertise and know how. We combine heritage, cutting-edge technology and design in each stitch to offer feel better, sustainable products with a wow effect.`,
      cta: {
        title: "learn more",
        handle: "https://itemm6usa.com/pages/compression-benefits",
      },
      collectionImages: [
        "https://firebasestorage.googleapis.com/v0/b/item-m6.appspot.com/o/PoweredByScience%2FMesh%2FpoweredByScience1.jpg?alt=media&token=4a6fcd19-b265-4eed-989d-f33bbac983d6",
        "https://firebasestorage.googleapis.com/v0/b/item-m6.appspot.com/o/PoweredByScience%2FMesh%2FpoweredByScience2.jpg?alt=media&token=b2f91881-e7a6-4174-b677-2a92b0f79c29",
        "https://firebasestorage.googleapis.com/v0/b/item-m6.appspot.com/o/PoweredByScience%2FMesh%2FpoweredByScience3.jpg?alt=media&token=1f95ff85-de28-4e19-8b39-9a30f6492553",
        "https://firebasestorage.googleapis.com/v0/b/item-m6.appspot.com/o/PoweredByScience%2FMesh%2FpoweredByScience.png?alt=media&token=c1294372-9ac7-4242-ac54-1bc167287847",
      ],
    },
  },
  highlightCard: {
    powerpants: [
      {
        title: "POWER MESH POSTURE SHIRT",
        image:
          "https://firebasestorage.googleapis.com/v0/b/item-m6.appspot.com/o/HighlightCards%2FMesh%2Fpower-mesh-angel.png?alt=media&token=ad39a47d-f34e-4eea-a5f7-6d278bd6155b",
        primaryDescription:
          "Posture, baby! Fashion meets function with this posture shirt that reminds you through its smart design to adopt the perfect posture. It stimulates inactive back muscles and offers you innovative back support all day long. The mesh detailing embraces you with the power of the ultimate in high-tech materials and sculpts you gently into shape.",
        cta: {
          title: "shop now",
          handle:
            "https://itemm6usa.com/products/power-mesh-angel-posture-shirt",
        },
      },
      {
        title: "ARM SHAPER LONGSLEEVE TOP",
        image:
          "https://firebasestorage.googleapis.com/v0/b/item-m6.appspot.com/o/HighlightCards%2FPowerPants%2Farm-shaper.png?alt=media&token=ba439a8e-63d5-4c0c-868c-c6bec4bc0d5d",
        primaryDescription:
          "Super soft, comfortable and yet highly effective at providing a gentle shaping effect for the arms. Perfect for layering under a multitude of outfits. Make your mark while being fashionable, confident and yet comfortable.",
        secondaryDescription: "Made in Italy.",
        cta: {
          title: "shop now",
          handle:
            "https://itemm6usa.com/products/power-mesh-angel-posture-shirt",
        },
      },
    ],
    mesh: [
      {
        title: "POWER MESH POSTURE SHIRT",
        image:
          "https://firebasestorage.googleapis.com/v0/b/item-m6.appspot.com/o/HighlightCards%2FMesh%2Fpower-mesh-angel.png?alt=media&token=ad39a47d-f34e-4eea-a5f7-6d278bd6155b",
        primaryDescription:
          "Posture, baby! Fashion meets function with this posture shirt that reminds you through its smart design to adopt the perfect posture. It stimulates inactive back muscles and offers you innovative back support all day long. The mesh detailing embraces you with the power of the ultimate in high-tech materials and sculpts you gently into shape.",
        cta: {
          title: "shop now",
          handle:
            "https://itemm6usa.com/products/power-mesh-angel-posture-shirt",
        },
      },
      {
        title: "CROPPED HIGH WAIST JEANS",
        image:
          "https://firebasestorage.googleapis.com/v0/b/item-m6.appspot.com/o/HighlightCards%2FMesh%2Fhigh_waist_jeans.jpg?alt=media&token=017611e2-81f0-4f99-a651-88de88b9b573",
        primaryDescription:
          "These jeans are the luxury version of the classic 5-pocket jeans.  The patented shape technology contours the legs and lifts the bottom is extremely comfortable and at the same time provides support - 24/7. Stylish short fringes on the outside of the legs offer an element of design and versatility - wear them dressy or casual. ",
        secondaryDescription:
          "European Made. We have combined high sustainability standards, responsible production methods, and the finest materials for amazing, feel good products that are European sourced and made. ",
        cta: {
          title: "shop now",
          handle:
            "https://itemm6usa.com/collections/shaping-jeans-pants/products/denim-high-rise-pants",
        },
      },
    ],
  },
  feelingGood: {
    title: "FEELING GOOD NEVER LOOKED SO GOOD",
    description: `ITEM m6’s complete line of bodywear helps you look your best and feel your best whether you wear it beneath or flaunt it for the world to see.`,
    collectionImages: [
      "https://firebasestorage.googleapis.com/v0/b/item-m6.appspot.com/o/FeelingGood%2FPowerpants.png?alt=media&token=3ade7613-c952-48c3-80fd-55d73ac807ed",
      "https://firebasestorage.googleapis.com/v0/b/item-m6.appspot.com/o/FeelingGood%2FMadetobestrong_All_Mesh_Body.png?alt=media&token=98a3565b-251b-41ea-a9d4-b2ece99d2169",
      "https://firebasestorage.googleapis.com/v0/b/item-m6.appspot.com/o/FeelingGood%2FAlejandra.png?alt=media&token=117b9aa0-b389-4297-b13c-d18a05d8f545",
      "https://firebasestorage.googleapis.com/v0/b/item-m6.appspot.com/o/FeelingGood%2FPowerPants_Pleated_Rider.png?alt=media&token=dd79ff77-41ac-4644-8e78-2258dbc62628",
    ],
  },
}
