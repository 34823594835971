import { Link } from "gatsby"
import React from "react"
import styled from "styled-components"
import BodywearCarousal from "./bodywearCarousal"

const StyledWrapper = styled.div`
  .bodywearContainer {
    margin-bottom: 50px;
    background: #f1e9de;
    padding-bottom: 30px;
  }

  .bodywearText p {
    line-height: 0.4;
  }

  .bodywearImages img {
    display: block;
    margin: 0 auto;
  }

  .slick-carousel {
    height: 300px;
  }

  a:hover {
    text-decoration: none;
    color: #111;
  }

  @media (max-width: 767px) {
    .bodywearContainer {
      max-height: 750px;
      margin-bottom: 30px;
      padding-bottom: 30px;
    }
  }

  @media (min-width: 768px) and (max-width: 991px) {
    .bodywearText p {
      line-height: 1.4;
    }
  }
  @media (min-width: 992px) and (max-width: 1999px) {
    .bodywearText p {
      line-height: 1.5;
    }
  }
`

const bodyWear = props => {
  return (
    <StyledWrapper>
      <Link to='https://itemm6usa.com/'>
      <div className="container-fluid py-5 bodywearContainer">
        <div className="row">
          <div className="col-7 mx-auto text-center">
            <h2 className="text-uppercase mb-3 d-md-block d-none">
              {props?.title}
            </h2>
            <div className="d-lg-block d-none bodywearText">
              <p>{props?.description}</p>
            </div>
            <div className="d-lg-none d-md-block d-none bodywearText">
              <p>{props?.description}</p>
            </div>
          </div>
        </div>
        <div className="row d-md-none d-sm-block">
          <div className="col-11 mx-auto text-center text-uppercase">
            <h2 className="mb-3">{props?.title}</h2>
          </div>
        </div>
        <div className="row d-md-none d-sm-block my-2">
          <div className="col-12 text-center font-weight-light">
            <p>{props?.description}</p>
          </div>
        </div>
        <div className="container">
          <div className="row mt-4 mx-auto align-items-center bodywearImages d-md-flex d-none">
            <div className="col-12 d-flex mt-4 align-items-center">
              <div className="col-3">
                <img src={props?.collectionImages[0]} alt="" className="img-fluid" />
              </div>
              <div className="col-3">
                <img src={props?.collectionImages[1]} alt="" className="img-fluid" />
              </div>
              <div className="col-3">
                <img src={props?.collectionImages[2]} alt="" className="img-fluid" />
              </div>
              <div className="col-3">
                <img
                  src={props?.collectionImages[3]}
                  alt=""
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="row d-sm-block mx-auto d-md-none">
          <div className="col-12">
            <BodywearCarousal images={props.collectionImages}/>
          </div>
        </div>
      </div>
      </Link>
    </StyledWrapper>
  )
}

export default bodyWear
