import { Link } from "gatsby"
import React from "react"
import styled from "styled-components"

const StyledWrapper = styled.div`
  button {
    margin-top: 30px;
    width: 161px;
  }

  @media (min-width: 992px){
      .highlight_card_image{
        min-height: 625px;
        max-height: 625px;
        object-fit:cover;
    }
  }

  @media (min-width: 320px) and (max-width: 575px) {
    .highlightText {
      display: none;
    }
    .highlight_card_image{
        height: 70vh;
        object-fit:cover;
    }
  }

  @media (max-width: 767px) {
    button {
      width: 100%;
    }
    .highlight_card_image{
        height: 70vh;
        object-fit:cover;
    }
    h3,
    p {
      text-align: left;
    }
  }
`

const featureHighlightCard1 = (props) => {
    return (
        <StyledWrapper>
            <div className="container my-5">
                <div className="row align-items-center flex-wrap-reverse mt-5">
                    <div className="col-md-5 d-flex flex-column justify-content-start">
                        <h2 className="mb-3">{props[0].title}</h2>
                        {/* Display only in Desktop and above */}
                        <div className="d-lg-block d-md-none d-sm-none highlightText">
                            <p>
                                {props[0]?.primaryDescription}
                            </p>
                        </div>
                        {/* Display only in medium devices */}
                        <div className="d-md-block d-lg-none d-sm-none highlightText">
                            <p>
                                {props[0]?.primaryDescription}
                            </p>
                        </div>
                        {/* Display only in smaller devices */}
                        <p className="d-sm-block d-md-none d-lg-none">
                            {props[0]?.primaryDescription}
                        </p>
                        <Link to={props[0]?.cta.handle}>
                            <button className="btn btn-dark"> {props[0]?.cta.title}</button>
                        </Link>
                    </div>
                    <div className="offset-lg-1 col-lg-5 col-md-7 ms-lg-n5">
                        <div className="">
                            <img
                                src={props[0]?.image}
                                alt=""
                                className="d-block w-100 highlight_card_image mx-auto mb-2"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </StyledWrapper>
    )
}

export default featureHighlightCard1
