import React from "react"
import styled from "styled-components"

const StyledWrapper = styled.div`
  section {
    min-height: 550px;
  }

  p {
    font-weight: 600;
  }

  .options-container {
    background-repeat: no-repeat;
    background-size: cover;
  }

  .optionsContainer {
    position: relative;
    height: 390px;
    width: 100%;
  }

  @media (min-width: 1200px) {
    .option {
      width: 340px;
    }

    .option:hover {
      width: 100%;
      height: 100%;
      z-index: 1;
    }

    /* Heading Selectors */
    .option:hover h5 {
      width: 50%;
    }
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    .option {
      width: 285px;
    }

    /* Heading Selectors */
    .option:hover h5 {
      width: 46%;
    }
  }

  h3 {
    font-weight: 900;
    font-size: 24px;
  }

  h5 {
    width: 85%;
    margin-top: 20px;
    margin-left: 10px;
    padding-top: 30px;
    background-image: linear-gradient(#e6c1b6, #e6c1b6 100%);
    background-position: top left;
    background-size: 60px 8px;
    background-repeat: no-repeat;
    text-transform: none;
    font-weight: 900;
  }

  /* Selecting all images */

  img {
    min-height: 270px;
    max-width: 280px;
    object-fit: contain;
  }

  @media (min-width: 992px) {
    .option1 {
      top: 0;
      left: 0;
    }

    .option2 {
      top: 0;
      right: 0;
    }

    .option3 {
      bottom: 0;
      left: 0;
    }

    .option4 {
      bottom: 0;
      right: 0;
    }

    .option {
      display: flex;
      height: 180px;
      position: absolute;
      transition: height 0.1s, width 0.1s;
      background: #ffffff;
      overflow: hidden;

      .option-text {
        width: 100%;

        p {
          display: none;
        }
      }

      .option-img {
        display: none;
        padding-left: 20px;
      }
    }

    p {
      font-weight: 400;
    }

    .option:hover {
      width: 100%;
      height: 100%;
      z-index: 1;

      .option-text,
      .option-img {
        width: 50%;
      }

      .option-text {
        p {
          display: flex;
        }
      }

      .option-img {
        display: flex;
      }
    }

    .option2:hover {
      height: 106%;
    }
  }
`

const extraOptions = props => {
  return (
    <StyledWrapper>
      <section className="options-container d-lg-block d-none" style={{backgroundImage:`url(${props.backgroundBanner})`}}>
        <div className="row my-5 py-5 mx-auto container">
          <div className="col-3 d-flex flex-column justify-content-center ms-5 mr-n1">
            <h2 className="mb-3 text-uppercase">{props?.title}</h2>
            <p>{props?.description}</p>
          </div>
          <div className="col-8">
            <div className="optionsContainer">
              {/* Option1 */}
              <div className="option option1 p-3">
                <div className="option-text">
                  <h5 className="w-75">{props?.boxData[0].title}</h5>
                  <p className="px-2">{props?.boxData[0].description}</p>
                </div>

                <div className="option-img mt-5 option1ImageContainer font-weight-bolder">
                  <img
                    src={props?.boxData[0].image}
                    alt=""
                    className="px-2 mx-auto"
                  />
                </div>
              </div>

              {/* Option2 */}
              <div className="option option2 p-3">
                <div className="option-text">
                  <h5 className="w-75">{props?.boxData[1].title}</h5>
                  <p className="px-2">{props?.boxData[1].description}</p>
                </div>

                <div className="option-img mt-5 option1ImageContainer font-weight-bolder">
                  <img
                    src={props?.boxData[1].image}
                    alt=""
                    className="px-2 mx-auto"
                  />
                </div>
              </div>

              {/* Option3 */}
              <div className="option option3 p-3">
                <div className="option-text">
                  <h5 className="w-75">{props?.boxData[2].title}</h5>
                  <p className="px-2">{props?.boxData[2].description}</p>
                </div>

                <div className="option-img mt-5 option1ImageContainer font-weight-bolder">
                  <img
                    src={props?.boxData[2].image}
                    alt=""
                    className="px-2 mx-auto"
                  />
                </div>
              </div>

              {/* Option4 */}
              <div className="option option4 p-3">
                <div className="option-text">
                  <h5 className="w-75">{props?.boxData[3].title}</h5>
                  <p className="px-2">{props?.boxData[3].description}</p>
                </div>

                <div className="option-img mt-5 option1ImageContainer font-weight-bolder">
                  <img
                    src={props?.boxData[3].image}
                    alt=""
                    className="px-2 mx-auto"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </StyledWrapper>
  )
}

export default extraOptions
