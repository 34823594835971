import React from "react"
import fill3 from "../images/Feature Bottom/Fill 15.svg"
import fill1 from "../images/Object.png"
import fill2 from "../images/Object1.png"
import styled from "styled-components"

const StyledWrapper = styled.div`
  img {
    height: 90px;
    width: 70px;
  }

  p {
    width: 80%;
    margin: 0 auto;
    font-size: 14px;
    font-weight: 200;
  }

  @media (max-width: 767px) {
    .featureBottomContainer {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
    }

    .col-sm-12 div {
      margin-bottom: 25px;
    }
  }
`

const featureBottom = () => {
  return (
    <StyledWrapper>
      <div className="container">
        <div className="row">
          <div className="col-10 mx-auto mt-5 mb-3 text-center">
            <h2 className="mb-3">NEXT-LEVEL SHAPEWEAR</h2>
          </div>
        </div>
        <div className="row pb-5 d-flex justify-content-between">
            <div className="col-12 col-md-6 col-lg-4 text-center mt-5">
              <img src={fill1} alt="" />
              <h6 className="mt-4">BODY BENEFITS</h6>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
            </div>
            <div className="col-12 col-md-6 col-lg-4 text-center mt-5">
              <img src={fill2} alt="" />
              <h6 className="mt-4 text-uppercase">CONTOURING TECHNOLOGY</h6>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
            </div>
            <div className="col-12 col-md-6 col-lg-4 text-center mt-5">
              <img src={fill3} alt="" />
              <h6 className="mt-4">INTELLIGENT DESIGN</h6>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
            </div>
          </div>
      </div>
    </StyledWrapper>
  )
}

export default featureBottom
