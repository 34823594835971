import React, { Component } from "react"
import Slider from "react-slick"
import styled from "styled-components"

const StyledWrapper = styled.div`
  .slick-dots {
    position: relative;
    left: -22px;
    bottom: 10px;
    padding-top: 20px;
  }

  .mobile_hero_img{
    min-height: 50vh;
    max-height: 50vh;
    object-fit:cover;
  }

  a {
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 100px;
    background: #111;
    cursor: pointer;
    transition: 0.3s ease-in-out;
  }

  .slick-dots ul li {
    margin: 0 !important;
  }

  .slick-dots ul .slick-active a {
    width: 18px;
  }
`

export default class topCarouselMobile extends Component {
  constructor(props) {
    super(props)
    this.state = {
      nav1: null,
      nav2: null,
    }
  }

  componentDidMount() {
    this.setState({
      nav1: this.slider1,
      nav2: this.slider2,
    })
  }
  render() {
    const settings = {
      customPaging: function (i) {
        return <a></a>
      },
      appendDots: dots => (
        <StyledWrapper>
          <div>
            <ul style={{ margin: "0px" }}> {dots} </ul>
          </div>
        </StyledWrapper>
      ),
      dots: true,
      dotsClass: "slick-dots slick-thumb",
      focusOnSelect: false,
    }
    return (
      <StyledWrapper>
        <div className="container d-block d-lg-none">
          <div className="row">
            <div className="col-12 px-0 pb-5">
              <Slider
                asNavFor={this.state.nav2}
                ref={slider => (this.slider1 = slider)}
                arrows={false}
                infinite={false}
                focusOnSelect={false}
              >
                {Object.keys(this.props).map(item => (
                  <div>
                    <img
                      src={this.props[item].image}
                      alt=""
                      className="w-100 img-fluid mobile_hero_img"
                    />
                  </div>
                ))}
              </Slider>
            </div>
          </div>
          <div className="row">
            <div className="col-11 mx-auto">
              <Slider
                asNavFor={this.state.nav1}
                ref={slider => (this.slider2 = slider)}
                slidesToShow={1}
                swipeToSlide={true}
                focusOnSelect={true}
                arrows={false}
                infinite={false}
                {...settings}
              >
                {Object.keys(this.props).map(item => (
                  <div
                    className="d-flex flex-column carouselText text-center"
                    key={item}
                  >
                    <h2 className="mb-3 text-uppercase">
                      {this.props[item].heading}
                    </h2>
                    <p>{this.props[item].description}</p>
                    <button
                      className="btn btn-dark mt-3"
                      onClick={() =>
                        (window.location.href =
                          "https://itemm6usa.com/collections/all-mesh-collection")
                      }
                    >
                      choose your style
                    </button>
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        </div>
      </StyledWrapper>
    )
  }
}
