import React from "react"
import styled from "styled-components"
import bitmap from "../images/Bitmap.svg"
import visa from "../images/Payment Icons/visa.svg"
import discover from "../images/Payment Icons/Discover.svg"
import paypal from "../images/Payment Icons/paypal.svg"
import ic_maestro from "../images/Payment Icons/ic_maestro.svg"
import ic_mastercard from "../images/Payment Icons/ic_mastercard.svg"
import applepay from "../images/Payment Icons/applepay.svg"
import facebook from "../images/Social Media/Facebook.svg"
import twitter from "../images/Social Media/Twitter.svg"
import instagram from "../images/Social Media/Instagram.svg"
import { Link } from "gatsby"

const FooterTitles = styled.p`
  color: white;
  font-size: ${props => props.theme["footerTitleSize"]};
  letter-spacing: 1.5px;
  margin-bottom: 30px;
`

const FooterText = styled.p`
  color: white;
  opacity: 0.6;
  font-size: ${props => props.theme["footerTextSize"]};
  cursor: pointer;

  :hover {
    opacity: 1;
  }
`

const StyledWrapper = styled.div`
  background: black;

  a{
    color: white;
    :hover{
      text-decoration: none;
    }
  }

  .footer__logo {
    width: 100%;
    height: 70%;
    object-fit: contain;
  }

  hr {
    background: grey;
    opacity: 0.4;
   
  }

  .paymentOptions {
    opacity: 0.5;
    display: flex;
    justify-content: center;
  }

  .social_media {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .social_media span {
    color: white;
    margin-right: 10px;
  }

  .copyrightMessage span {
    color: white;
  }

  .social_media img {
    margin: 0 8px;
  }

  .copyright_container {
    padding: 20px 0;
  }

  @media (max-width: 991px) {
    .copyright_container div {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 10px 0;
    }
  }
`

const footer = () => {
  return (
    <StyledWrapper>
      <div className="container">
        <div className="row pt-4">
          <div className="col-lg-2 col-4">
            <img className="img-fluid mb-3" src={bitmap} alt="" />
          </div>
          <div className="col-lg-2 col-12 mb-5">
            <FooterTitles>COMPANY INFO</FooterTitles>
            <Link to="https://itemm6usa.com/pages/compression-benefits">
              <FooterText>About ITEM m6</FooterText>
            </Link>
            <Link to="https://www.mediusa.com/"><FooterText>Medi USA</FooterText></Link>
            <Link to="https://itemm6usa.com/pages/in-the-press"><FooterText>Press</FooterText></Link>
          </div>
          <div className="col-lg-3 col-12 mb-5">
            <FooterTitles>SUPPORT + SERVICE</FooterTitles>
            <Link to="https://itemm6usa.com/pages/contact-us"><FooterText>Contact Us</FooterText></Link>
            <Link to="https://itemm6usa.com/pages/returns-reorders"><FooterText>Returns + Reorders</FooterText></Link>
          </div>
          <div className="col-lg-2 col-12 mb-5">
            <FooterTitles>SHOP ITEM M6</FooterTitles>
            <Link to="https://itemm6usa.com/collections/all"><FooterText>Shop All Products</FooterText></Link>
            <Link to="https://itemm6usa.com/pages/legwear-sizing"><FooterText>Size Charts</FooterText></Link>
          </div>
          <div className="col-lg-3 col-12 mb-5">
            <FooterTitles>NEED HELP?</FooterTitles>
            <Link to="https://itemm6usa.com/pages/contact-us"><FooterText>Live Chat</FooterText></Link>
            <FooterText>
              <a href="tel:8666656606">Call: 1 (866) 665-6606</a>
            </FooterText>
            <Link to="https://mail.google.com/mail/u/0/?view=cm&fs=1&tf=1&source=mailto&to=info@itemm6usa.com"><FooterText>Email: info@itemm6usa.com</FooterText></Link>
          </div>
        </div>
        <hr />
        <div className="row justify-content-between flex-wrap-reverse copyright_container">
          <div className="col-lg-4 col-12 copyrightMessage">
            <span>&#169; 2021 ITEM m6</span>
          </div>
          <div className="col-lg-4 col-12 paymentOptions">
            <div>
              <img src={visa} alt="" />
            </div>
            &nbsp;&nbsp;
            <div>
              <img src={ic_mastercard} alt="" />
            </div>
            &nbsp;&nbsp;
            <div>
              <img src={ic_maestro} alt="" />
            </div>
            &nbsp;&nbsp;
            <div>
              <img src={paypal} alt="" />
            </div>
            &nbsp;&nbsp;
            <div>
              <img src={discover} alt="" />
            </div>
            &nbsp;&nbsp;
            <div>
              <img src={applepay} alt="" />
            </div>
          </div>
          <div className="col-lg-4 col-12 social_media">
            <span>Follow Us: </span>
            <Link to="https://www.facebook.com/ITEMm6USA/"><img src={facebook} alt="" /></Link>
            <Link to="https://twitter.com/ITEMm6USA"><img src={twitter} alt="" /></Link>
            <Link to="https://www.instagram.com/item_m6/"><img src={instagram} alt="" /></Link>
          </div>
        </div>
      </div>
    </StyledWrapper>
  )
}

export default footer
