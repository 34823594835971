import { Link } from "gatsby"
import React from "react"
import Slider from "react-slick"
import styled from "styled-components"

const StyledWrapper = styled.div`
  .carousel1 {
    min-height: 500px;
    max-height: 500px;

    background-image: linear-gradient(#e6c1b6, #e6c1b6 100%);
    background-size: 40% 40%;
    background-repeat: no-repeat;
    background-position: top left;

    padding: 20px 20px;
    margin-top: -20px;
  }

  .carousel1 div img {
    min-height: 650px;
    max-height: 650px;
    min-width: 550px;
    max-width: 550px;
    object-fit: cover;
  }

  .carousel2 div img {
    min-height: 260px;
    max-height: 260px;
    object-fit: cover;
  }

  svg {
    cursor: pointer;
  }

  .carousel2 {
    margin-top: 20px;
    cursor: pointer;
  }

  .carousel2 .slick-slide {
    padding: 0 4px;
  }

  /* .carousel2 .slick-list {
    margin: 0 -22px;
  } */

  .carousel2 div[data-index="0"] {
    display: none;
  }

  .max-subtext {
    max-width: 400px;
  }
`

export default class carouselMiddle extends React.Component {
  constructor(props) {
    super(props)
    this.next = this.next.bind(this)
    this.previous = this.previous.bind(this)
    this.state = {
      nav1: null,
      nav2: null,
      activeSlide: 0,
    }
  }

  next() {
    this.slider.slickNext()
  }
  previous() {
    this.slider.slickPrev()
  }

  componentDidMount() {
    this.setState({
      nav1: this.slider1,
      nav2: this.slider2,
    })
  }

  render() {
    return (
      <StyledWrapper>
        <div className="container my-5 py-5 d-lg-block d-none">
          <div className="row flex-row ms-lg-5 ps-lg-3">
            <div className="col-6 carousel1">
              <Slider
                asNavFor={this.state.nav2}
                ref={slider => (this.slider1 = slider)}
                arrows={false}
                focusOnSelect={false}
              >
                {this.props?.carouselData.map(({ image }) => (
                  <div>
                    <img
                      className="w-100 img-fluid carousel1Img"
                      src={image}
                      alt=""
                    />
                  </div>
                ))}

              </Slider>
            </div>
            <div className="col-6 mt-4">
              <div className="d-flex justify-content-between">
                <h2 className="mb-3 text-uppercase font-weight-bolder">
                  {this.props?.title}
                </h2>
                <div className="d-flex justify-content-end mt-n2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-chevron-left d-none d-lg-block"
                    viewBox="0 0 16 16"
                    onClick={this.previous}
                  >
                    <path
                      fillRule="evenodd"
                      d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
                    />
                  </svg>
                  &nbsp; &nbsp;
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-chevron-right d-none d-lg-block"
                    viewBox="0 0 16 16"
                    onClick={this.next}
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
                    />
                  </svg>
                </div>
              </div>
              <p className="max-subtext">{this.props?.primaryDescription}</p>
              <div className="carousel2">
                <Slider
                  asNavFor={this.state.nav1}
                  ref={(c, slider) => {
                    this.slider = c
                    this.slider2 = slider
                  }}
                  focusOnSelect={true}
                  slidesToShow={3}
                  swipeToSlide={true}
                  arrows={false}
                  afterChange={current =>
                    this.setState({ activeSlide: current })
                  }
                >
                  {this.props?.carouselData.map(({ image }) => (
                    <div>
                      <img
                        className="w-100 img-fluid carousel2Img"
                        src={image}
                        alt=""
                      />
                    </div>
                  ))}
                </Slider>
              </div>
              <h5 className="text-capitalize mt-3 font-weight-bolder">
                {this.props?.carouselData[this.state.activeSlide].heading}
              </h5>
              <p className="max-subtext">
                {this.props?.carouselData[this.state.activeSlide].description}
              </p>
              <p className="font-weight-bold">
                {this.props?.carouselData[this.state.activeSlide].price}
              </p>
              <Link to={this.props?.carouselData[this.state.activeSlide].shopLink}>
                <button
                  className="btn btn-dark w-25"
                  style={{ height: "45px" }}
                >
                  shop now
                </button>
              </Link>
            </div>
          </div>
        </div>
      </StyledWrapper>
    )
  }
}
