import React from "react"
import styled from "styled-components"

const StyledWrapper = styled.div`

  #discount {
    background-image: linear-gradient(#e6c1b6, #e6c1b6 100%);
    background-size: 95% 12px;
    background-repeat: no-repeat;
    background-position: center bottom;
  }

  /* Desktop SignUp Styles */
  .klaviyo-desktop{
    /* The Input Box */
    .lePSZB.lePSZB.lePSZB.lePSZB.lePSZB.lePSZB{
      padding: 0 0 0 5px;
      border-radius: 0;
      border: none;

      :hover{
        border: none;
      }
    }

    /* Button Styles */
    .hyGKlz.hyGKlz.hyGKlz.hyGKlz.hyGKlz.hyGKlz{
      height: 45px;
      padding: 0 35px;
    }

    /* Success Message Styles */
    .bwIBYc.bwIBYc.bwIBYc.bwIBYc.bwIBYc.bwIBYc .ql-editor{
      p{
        font-family:"Open Sans",serif;
        margin-bottom: 20px;

        strong{
         font-weight: 500;
         text-transform:uppercase;
        }
      }
    }
  }
  


  /* Mobile SignUp Styles */
  .klaviyo-mobile{
    /* Input Box styles */
    .kxWGRf.kxWGRf.kxWGRf.kxWGRf.kxWGRf.kxWGRf{
      padding: 0 0 0 5px;
    }

    /* Button Styles */
    .dMHKxR.dMHKxR {
      button{
       height: 25px;
      }
    }

    /* Success Message styles */
    .bwIBYc.bwIBYc.bwIBYc.bwIBYc.bwIBYc.bwIBYc .ql-container{
      p{
        font-family:"Open Sans",serif;
        margin-bottom: 20px;

        strong{
         font-weight: 500;
         text-transform:uppercase;
        }
      }
    }
    
  }
 
  
`

const signUp = () => {
  return (
    <StyledWrapper>
      <div className="container text-center py-5">
        <h4 className="font-weight-bolder">
          JOIN OUR LOYALTY PROGRAM AND
          <span id="discount">&nbsp;RECEIVE 20% OFF!&nbsp;</span>
        </h4>
        {/* Desktop SiGN-Up */}
        <div className="row d-lg-block d-none mt-4">
          <div className="col-5 mx-auto">
            <div class="klaviyo-form-WqP75Y klaviyo-desktop"></div>
          </div>
        </div>
        {/* Mobile Sign-Up */}
        <div className="row d-sm-block d-lg-none">
          <div className="col-12">
            <div class="klaviyo-form-Rx5eGu klaviyo-mobile"></div>
          </div>
        </div>
      </div>

    </StyledWrapper>
  )
}

export default signUp
