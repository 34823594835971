import React from "react"
import Slider from "react-slick"
import styled from "styled-components"

const StyledWrapper = styled.div`
  a {
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 100px;
    background: #111;
    cursor: pointer;
    transition: 0.3s ease-in-out;
  }

  .slick-dots ul li {
    margin: 0 !important;
  }

  .slick-dots ul .slick-active a {
    width: 18px;
  }

  svg {
    cursor: pointer;
    z-index: 999;
  }

  .carouselText {
    max-width: 450px;
    height: 350px;
    max-height: 350px;
  }

  @media (min-width: 992px) {
    h2 {
      max-width: 300px;
    }

    .hero-carousel-img {
      object-fit: cover;
      height: 70vh;
      max-height: 550px;
    }

    button {
      width: 50%;
    }

    .slick-dots div {
      position: relative;
      top: -55px;
    }

    .slick-dots {
      position: relative;
      left: -658px;
    }
  }

  @media (min-width: 1200px) {
    .slick-dots div {
      position: relative;
      top: -70px;
    }

    .slick-dots {
      position: relative;
      left: -785px;
    }
  }
`

export default class topCarousel extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      activeSlide: 0,
    }
    this.next = this.next.bind(this)
    this.previous = this.previous.bind(this)
  }

  next() {
    this.slider.slickNext()
  }

  previous() {
    this.slider.slickPrev()
  }

  render() {
    const settings = {
      customPaging: function (i) {
        return <a></a>
      },
      appendDots: dots => (
        <StyledWrapper>
          <div>
            <ul style={{ margin: "0px" }}> {dots} </ul>
          </div>
        </StyledWrapper>
      ),
      dots: true,
      dotsClass: "slick-dots slick-thumb",
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      afterChange: current => this.setState({ activeSlide: current }),
      focusOnSelect: false,
    }
    return (
      <StyledWrapper>
        <div className="container my-md-5 d-lg-block d-none">
          <div className="row flex-wrap-reverse">
            <div className="col-lg-5 col-12 d-flex flex-column justify-content-around ">
              <div className="d-flex flex-column carouselText">
                <h2 className="mb-3">
                  {this.props[this.state?.activeSlide]?.heading}
                </h2>
                <p className="pe-5">
                  {this.props[this.state?.activeSlide]?.description}
                </p>
                <button
                  className="btn btn-dark mt-3"
                  onClick={() =>
                    (window.location.href =
                      "https://itemm6usa.com/collections/all-mesh-collection")
                  }
                >
                  choose your style
                </button>
              </div>
              <div className="d-flex justify-content-end">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-chevron-left d-none d-lg-block"
                  viewBox="0 0 16 16"
                  onClick={this.previous}
                >
                  <path
                    fillRule="evenodd"
                    d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
                  />
                </svg>
                &nbsp; &nbsp;
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-chevron-right d-none d-lg-block"
                  viewBox="0 0 16 16"
                  onClick={this.next}
                >
                  <path
                    fillRule="evenodd"
                    d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
                  />
                </svg>
              </div>
            </div>
            <div className="col-lg-7 col-11 mx-auto carouselImageContainer">
              <Slider ref={c => (this.slider = c)} {...settings}>
                {Object.keys(this.props).map(item => (
                  <div>
                    <img
                      src={this.props[item].image}
                      alt=""
                      className="w-100 img-fluid hero-carousel-img"
                    />
                  </div>
                ))}
                {/* <div>
                  <img
                    src={this.props[0]?.image}
                    alt=""
                    className="w-100 img-fluid hero-carousel-img"
                  />
                </div>
                <div>
                  <img
                    src={this.props[1]?.image}
                    alt=""
                    className="w-100 img-fluid hero-carousel-img"
                  />
                </div>
                <div>
                  <img
                    src={this.props[2]?.image}
                    alt=""
                    className="w-100 img-fluid hero-carousel-img"
                  />
                </div> */}
              </Slider>
            </div>
          </div>
        </div>
      </StyledWrapper>
    )
  }
}
