import React from "react"
import feather from "../images/Feature Top/feather.svg"
import femaleicon from "../images/Feature Top/femaleIcon.svg"
import star from "../images/Feature Top/star.svg"
import thread from "../images/Feature Top/Thread.svg"
import styled from "styled-components"

const StyledWrapper = styled.div`
  h3 {
    font-weight: 800;
  }

  .feature-top {
    background: #f1e9de;
    border-radius: 50%;
    width: 132px;
    height: 132px;
    display: flex;
    margin: auto;
  }

  .surprise {
    width: 100%;
    max-width: unset;
  }

  @media (max-width: 767px) {
    .feature-top {
      background: #ffffff;
      width: 150px;
      height: 150px;
      display: flex;
    }
    .feature-top-container {
      background: #f1e9de;
    }

    .col-6 {
      margin-bottom: 20px;
    }

    .feature-top img {
      width: 50px;
    }

    .feature-top #femaleIcon {
      width: 50px;
      height: 60px;
      margin: auto;
    }
  }

  @media (min-width: 768px) and (max-width: 991px) {
    .feature-top {
      background: #f1e9de;
      width: 130px;
      height: 130px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-left: 30px;
    }

    .feature-top img {
      width: 40px;
      height: 40px;
    }

    .feature-top #femaleIcon {
      width: 40px;
      height: 50px;
    }

    .col-md-2 {
      margin: 0px 20px;
    }

    p {
      width: 110px;
      margin: 0 40px;
    }
  }

  p {
    margin-top: 20px;
    max-width: 200px;
    margin: 20px auto 0px auto;
    font-weight: 400;
  }

  .sub-text {
    font-size: 16px;
    font-weight: 400;
    max-width: 500px;
  }

  

  /* Target Only Mozilla Firefox Browsers */
  @-moz-document url-prefix() {
    @media (min-width: 992px) {
      .feature-top {
        background: #f1e9de;
        width: 130px;
        height: 130px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-left: 30px;
      }
      img {
        width: 50px;
        height: 50px;
      }
    }

    
  }
`

const feautureTop = () => {
  let classNameImage = "d-block mx-auto"

  return (
    <StyledWrapper>
      <div className="container-sm-fluid pt-5 py-md-5 my-5 feature-top-container surprise">
        <div className="row text-center container mx-auto">
          <div className="col-12 col-md-7 mx-auto">
            <h2 className="mb-3">ALLOW US TO SURPRISE YOU</h2>
            <p className="sub-text">
              Find out what happens when compression technology developed for
              the medical industry finds it’s way to the world of fashion.
            </p>
          </div>
        </div>
        <div className="row my-5">
          <div className="col-lg-3 col-md-2 col-6 text-center">
            <div className="feature-top">
              <img src={feather} alt="" className={classNameImage} />
            </div>
            <div className="text-center">
              <p>Unparalleled All-Day Comfort</p>
            </div>
          </div>
          <div className="col-lg-3 col-md-2 col-6">
            <div className="feature-top">
              <img
                src={femaleicon}
                alt=""
                className={classNameImage}
                id="femaleIcon"
              />
            </div>
            <div className="text-center">
              <p>Elegant styles help you look your best</p>
            </div>
          </div>
          <div className="col-lg-3 col-md-2 col-6">
            <div className="feature-top">
              <img src={star} alt="" className={classNameImage} />
            </div>{" "}
            <div className="text-center">
              <p>Thoughtfully designed features and details</p>
            </div>
          </div>
          <div className="col-lg-3 col-md-2 col-6">
            <div className="feature-top">
              <img src={thread} alt="" className={classNameImage} />
            </div>{" "}
            <div className="text-center">
              <p>Superior German Craftsmanship</p>
            </div>
          </div>
        </div>
      </div>
    </StyledWrapper>
  )
}

export default feautureTop
