import React, { useState } from "react"
import styled from "styled-components"

const StyledWrapper = styled.div`
  .options-container {
    background-repeat: no-repeat;
    background-size: cover;
  }

  .optionsContainer div {
    margin-bottom: 30px;
  }

  button {
    border: none !important;
    background: none !important;
    top: 20px;
    right: 20px;
    position: absolute;
    font-size: 20px !important;
  }

  .option1,
  .option2,
  .option3,
  .option4 {
    min-height: 150px;
    width: 100%;
    background: #ffffff;
    overflow: hidden;
  }

  .modal {
    overflow-y: scroll;
    min-height: 100vh;
    max-height: 100vh;
    min-width: 100%;
  }

  .modal-dialog {
    min-width: 100%;
    min-height: 100vh;
    margin: 0;
    padding: 0;
  }

  .modal-content {
    border-radius: 0;
  }

  h5 {
    margin-top: 25px;
    margin-left: 10px;
    padding-top: 25px;
    background-image: linear-gradient(#e6c1b6, #e6c1b6 100%);
    background-position: top left;
    background-size: 80px 8px;
    background-repeat: no-repeat;
    text-transform: capitalize;
  }

  /* All Images */

  img {
    max-height: 70vh;
    max-width: 400px;
    object-fit: cover;
  }
`

function ExtraOptionsMedium(props) {
  const [showModal, setShowModal] = useState(false)
  const [indexClicked, setIndexClicked] = useState()
  return (
    <StyledWrapper>
      <div className="container-fluid options-container d-sm-block d-lg-none" style={{backgroundImage:`url(${props.backgroundBanner})`}}>
        <div className="row py-5">
          <div className="col-11 mx-auto">
            <div className="text-center bg-white p-3">
              <h2 className="mb-3 text-uppercase">{props?.title}</h2>
              <p className="font-weight-lighter">{props?.description}</p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-11 mx-auto">
            <div className="optionsContainer">
              {props?.boxData.map(({ title }, index) => {
                return (
                  <div
                    key={index}
                    className={`option${index + 1}`}
                    onClick={() => {
                      setIndexClicked(index)
                      setShowModal(true)
                    }}
                  >
                    <h5 className="mx-5 font-weight-bolder">{title}</h5>
                  </div>
                )
              })}
            </div>
          </div>
        </div>

        {/* Re-Usable Bootstrap Modal */}
        <div
          className="modal"
          tabIndex="-1"
          style={{ display: showModal ? "block" : "none" }}
          role="dialog"
          data-backdrop="static"
        >
          <div
            className="modal-dialog modal-lg modal-dialog-centered"
            role="document"
            onClick={() => setShowModal(false)}
          >
            <div className="modal-content">
              <div className="modal-header text-white">
                <h5 className="modal-title text-capitalize text-dark font-weight-bolder">
                  {props?.boxData[indexClicked]?.title}
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => setShowModal(false)}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <p>{props?.boxData[indexClicked]?.description}</p>
                <img
                  src={props?.boxData[indexClicked]?.image}
                  alt=""
                  className="d-block mx-auto"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </StyledWrapper>
  )
}

export default ExtraOptionsMedium
