import React, { useEffect, useState } from "react"
import styled from "styled-components"
import Hamburger from "hamburger-react"
import logo from "../images/logo_mobile.svg"
import searchIcon from "../images/SearchIconMobile.svg"
import { Link } from "gatsby"
const StyledWrapper = styled.div`
  .menu {
    position: absolute;
    left: 15px;
    top: 48px;
    padding: 0px 30px;
    background: white;
    z-index: 1;
    width: 100%;
    height: 100%;
  }

  .menu h5 {
    margin: 30px 0;
  }

  .cursor-pointer {
    cursor: pointer;
  }

  .hamburger {
    color: #ffffff;
  }

  a:hover {
    text-decoration: none;
    color: #111;
  }
`

const NavbarMobile = () => {
  const [hamClicked, setHamClicked] = useState(false)

  // To Prevent Scrolling on body when nav menu is open.
  useEffect(() => {
    if (hamClicked) {
      document.body.style.overflow = "hidden"
    }
    return () => {
      document.body.style.overflow = "unset"
    }
  }, [hamClicked])

  return (
    <StyledWrapper>
      <div className="container-fluid d-block d-lg-none no-scroll">
        <div className="row bg-dark align-items-center justify-content-center">
          <div className="col d-flex align-items-center hamburger">
            <Hamburger
              size={25}
              toggled={hamClicked}
              onToggle={() => setHamClicked(!hamClicked)}
            />
            <h5
              className="text-uppercase mt-2 text-white cursor-pointer"
              onClick={() => setHamClicked(!hamClicked)}
            >
              menu
            </h5>
          </div>
          <div className="col d-flex justify-content-center">
            <img className="img-fluid mt-n2" src={logo} alt="Item M6 Logo" />
          </div>
          <div className="col d-flex justify-content-end">
            <a href="https://itemm6usa.com/search">
              <img src={searchIcon} alt="" className="float-right" />
            </a>
          </div>
        </div>
        {hamClicked ? (
          <>
            <div className="row menu">
              <nav className="col-12 text-uppercase">
                <Link to="https://itemm6usa.com/collections/bodywear">
                  <h5>bodywear</h5>
                </Link>
                <Link to="https://itemm6usa.com/collections/legwear">
                  <h5>legwear</h5>
                </Link>
                <Link to="https://itemm6usa.com/pages/compression-benefits">
                  <h5>about item m6 </h5>
                </Link>
                <Link to="https://itemm6usa.com/pages/returns-reorders">
                  <h5>returns</h5>
                </Link>
                <Link to="https://itemm6usa.com/pages/contact">
                  <h5>contact us</h5>
                </Link>
                <h5>
                  <a href="tel:8666656606">Call: 1 (866) 665-6606</a>
                </h5>
                <Link to="https://itemm6usa.com/account/login">
                  <h5>login</h5>
                </Link>
              </nav>
            </div>
          </>
        ) : (
          ""
        )}
      </div>
    </StyledWrapper>
  )
}

export default NavbarMobile
