import React from "react"
import styled from "styled-components"

const StyledWrapper = styled.div`
  .pwdScienceContainer {
    background: #f1e9de;
    padding: 20px 40px;
  }

  .subtext-powered {
    max-width: 250px;
    text-align: left;
  }

  .subText p {
    max-width: 300px;
  }

  .subText a {
    text-decoration: underline;
  }

  .science-img {
    object-fit: contain;
    margin-bottom: auto;
    height: 200px;
    width: 200px;
  }

  .pwdScienceImg1 {
    min-width: 140px;
    max-width: 140px;
    min-height: 225px;
    max-height: 225px;
    object-fit: cover;
  }
  .pwdScienceImg2 {
    min-width: 320px;
    max-width: 320px;
    min-height: 350px;
    max-height: 350px;
    object-fit: cover;
  }
  .pwdScienceImg3 {
    min-width: 400px;
    max-width: 400px;
    min-height: 450px;
    max-height: 450px;
    object-fit: cover;
  }

  @media (min-width: 1200px) {
    .pwdScienceImg1 {
      min-width: 170px;
      max-width: 170px;
      min-height: 265px;
      max-height: 265px;
      object-fit: cover;
    }
    .pwdScienceImg2 {
      min-width: 360px;
      max-width: 360px;
      min-height: 420px;
      max-height: 420px;
      object-fit: cover;
    }
    .pwdScienceImg3 {
      min-width: 500px;
      max-width: 500px;
      min-height: 550px;
      max-height: 550px;
    }
  }
`

const poweredByScience = props => {
  return (
    <StyledWrapper>
      <div className="container-fluid d-md-block d-none pwdScienceContainer py-5">
        <div className="container">
          <div className="row">
            <div className="col-xl-11 d-flex justify-content-between mx-auto px-xl-5">
              <div className="mx-2">
                <img
                  src={props?.collectionImages[0]}
                  alt=""
                  className="align-top img-fluid pwdScienceImg1"
                />
              </div>
              <div className="mx-2">
                <img
                  src={props?.collectionImages[1]}
                  alt=""
                  className="align-top img-fluid pwdScienceImg2"
                />
                <div className="text-center mt-5 d-flex justify-content-end">
                  <h2 className="text-uppercase subtext-powered">
                    {props.title}
                  </h2>
                </div>
              </div>
              <div className="mx-2">
                <img
                  src={props?.collectionImages[2]}
                  alt=""
                  className="align-top img-fluid pwdScienceImg3"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-5">
          <div className="col-12 d-flex flex-row justify-content-center">
            <img
              src={props?.collectionImages[3]}
              alt=""
              className="align-top img-fluid science-img mt-4"
            />
            <div className="d-lg-block d-md-block d-none mx-3 mt-3 subText">
              <p>{props?.description}</p>
              <p className="mt-5 text-capitalize">
                <a href={props?.cta.handle}>
                  {props?.cta.title}
                </a>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="12"
                  fill="currentColor"
                  className="bi bi-chevron-right fw-bolder"
                  viewBox="0 0 16 16"
                >
                  <path
                    fillRule="evenodd"
                    d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
                  />
                </svg>
              </p>
            </div>
          </div>
        </div>
      </div>
    </StyledWrapper>
  )
}

export default poweredByScience
