import { Link } from "gatsby"
import React from "react"
import Slider from "react-slick"
import styled from "styled-components"

const StyledWrapper = styled.div`
  svg {
    cursor: pointer;
  }
  h3 {
    text-transform: uppercase;
    font-weight: 600;
  }

  .carousel1 div img {
    min-height: 320px;
    max-width: 100%;
    /* min-width: 230px; */
    max-height: 320px;
    object-fit: contain;
  }

  .carousel2 div img {
    min-height: 210px;
    max-height: 210px;
    object-fit: cover;
  }

  .carousel2 div[data-index="0"] {
    display: none;
  }
`

export default class carouselMiddleMobile extends React.Component {
  constructor(props) {
    super(props)
    this.next = this.next.bind(this)
    this.previous = this.previous.bind(this)
    this.state = {
      nav1: null,
      nav2: null,
      nav3: null,
      activeSlide: 0,
    }
  }

  next() {
    this.state.nav2.slickNext()
  }
  previous() {
    this.state.nav2.slickPrev()
  }

  componentDidMount() {
    this.setState({
      nav1: this.slider1,
      nav2: this.slider2,
      nav3: this.slider3,
    })
  }

  render() {
    return (
      <StyledWrapper>
        <div className="container my-5 py-5 d-sm-block d-lg-none">
          <div className="row">
            <div className="col-12 text-center">
              <h3>{this.props?.title}</h3>
              <p className="my-3">{this.props?.primaryDescription}</p>
            </div>
          </div>
          <div className="d-flex justify-content-end mt-n2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-chevron-left"
              viewBox="0 0 16 16"
              onClick={() => this.previous()}
            >
              <path
                fillRule="evenodd"
                d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
              />
            </svg>
            &nbsp; &nbsp;
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-chevron-right"
              viewBox="0 0 16 16"
              onClick={() => this.next()}
            >
              <path
                fillRule="evenodd"
                d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
              />
            </svg>
          </div>
          <div className="row flex-row align-items-center justify-content-center">
            <div className="col-8 carousel1">
              <Slider
                asNavFor={this.state.nav2}
                ref={slider => {
                  this.slider1 = slider
                }}
                arrows={false}
                focusOnSelect={false}
              >
                {this.props?.carouselData.map(({ image }) => (
                  <div>
                    <img className="w-100 img-fluid" src={image} alt="" />
                  </div>
                ))}
              </Slider>
            </div>
            <div className="col-4 pe-0">
              <div className="carousel2">
                <Slider
                  asNavFor={this.state.nav3}
                  ref={slider => {
                    this.slider2 = slider
                  }}
                  slidesToShow={1}
                  swipeToSlide={true}
                  focusOnSelect={false}
                  arrows={false}
                  afterChange={current =>
                    this.setState({ activeSlide: current })
                  }
                >
                  {this.props?.carouselData.map(({ image }) => (
                    <div>
                      <img
                        className="img-fluid carousel2Img"
                        src={image}
                        alt=""
                      />
                    </div>
                  ))}
                </Slider>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <Slider
                asNavFor={this.state.nav1}
                ref={slider => {
                  this.slider3 = slider
                }}
                arrows={false}
                focusOnSelect={false}
              >
                {this.props?.carouselData?.map((data, index) => {
                  return (
                    <div key={index}>
                      <h5 className="text-capitalize mt-3 font-weight-bolder">
                        {data.heading}
                      </h5>
                      <p className="mt-4">{data.description}</p>
                      <p className="font-weight-bolder mt-2">{data.price}</p>
                      <Link to={data.shopLink}>
                        <button className="w-100 btn btn-dark">shop now</button>
                      </Link>
                    </div>
                  )
                })}
              </Slider>
            </div>
          </div>
        </div>
      </StyledWrapper>
    )
  }
}
