import React from "react"
import Slider from "react-slick"
import powerPants from "../images/Bodywear/Powerpants.png"
import powerPants_Pleated_Rider from "../images/Bodywear/PowerPants_Pleated_Rider.png"
import alejandra from "../images/Bodywear/Alejandra.png"
import madeToBeStrong from "../images/Bodywear/Madetobestrong_All_Mesh_Body.png"
import styled from "styled-components"

const StyledWrapper = styled.div`
  .slick-slide img {
    max-height: 380px;
    min-height: 379px;
    max-width: 224px;
    object-fit: cover;
  }

  .slick-slide {
    margin: 0 10px;
  }
  .slick-list {
    margin: 0 -27px;
  }
`

const bodywearCarousal = props => {
  var settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    variableWidth: true,
  }
  return (
    <StyledWrapper>
      <Slider {...settings}>
        {props.images?.map(image => (
          <div>
            <img src={image} alt="" className="img-fluid" />
          </div>
        ))}
      </Slider>
    </StyledWrapper>
  )
}

export default bodywearCarousal
